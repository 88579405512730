import { JSONDSL } from "../../articleInterfaces";

export const content: JSONDSL = [
  "The night sky has always fascinated me. Growing up, I spent countless hours learning about the cosmos through just about every PBS series and documentary I could find. \"How The Universe Works,\" \"Nova\" and many others showed me black holes, colliding galaxies, icy moons and fiery gas giants. These were worlds beyond my reach, but not my imagination.",
  "I grew up in West Valley in the suburbs near Cottonwood Elementary. Each night, the glow of countless houses around me blotted out all but the brightest stars. I used to yearn to see the majestic, sweeping band of the milky way overhead.",
  "A few years ago, our family moved to a house on the side of a hill with a spectacular view across the entire Valley. My dad and I spent many nights out on our patio, watching the moon rise over the city.",
  "One time, he mentioned something that really stuck with me. Looking out across the Valley, across the sea of a thousand lights, a thousand houses, a thousand lives, he said each light is a person. Some people rarely think about what it means to keep the electric light on overhead. Other people don\'t know how much longer they\'ll be able to afford to keep it on. Some don\'t know when, or if, they\'ll be able to turn the light back on.",
  "I\'ve thought a lot about the night sky since then. Growing up, I only saw the endless distant worlds I could only imagine on the horizon. But beneath the sky above lies another beautiful sea of lights. All those human worlds lay connected all around me by the faint glow of all those houselights.",
  "On May 10, an aurora painted the entire night sky in brilliant greens and purples. A friend and I hiked up on a ridge to watch the light show overhead; the heavens adding their own colorful fingerprints to the human ones reaching up from below. In the valley, a thousand people I knew and a thousand people I didn\'t all stared in awe of the ghostly, silent, slowly moving lights above us. A thousand more were unaware of the spectacle at all.",
  "It\'s hard not to consider your place in life when watching the night sky; it raises questions about who you are and who you want to be.",
  "I made a promise to myself this year: live in the moment. I\'ve spent much of my 19 years wrapped up in whatever challenge is right in front of me. Laser focus defeats complacency, but the endless strive for excellence also has diminishing returns. For so long, I\'ve had my eyes glued to the distant horizon – the road ahead, the project due, the class to study for – that I sometimes wonder what moments I missed in that human glow below.",
  "I\'m by no means advocating complacency. I\'m proud of the work it took to get As in almost every class, graduate with my AA degree from Yakima Valley College, and where that work got me. I would do it all again in a heartbeat.",
  "Yet my fondest memories are the times I stepped back and had those special moments staring at the stars with my friends around a campfire, around a lake, or so many other meaningful moments.",
  "In September, I\'ll move into a dorm at the University of Washington. I\'ll be starting a new life, in a new world. It\'s comforting that the night sky will still be there, shining even brighter by the promise of a thousand new human fingerprints, a thousand new people to meet, a thousand new adventures to share.",
  "The night sky connects me to this valley, to the life I live now. It connects me to the life I\'m about to live. But it also connects all of us.",
  "I\'ll cherish the connections I\'ve made during these past four years at Unleashed. Thank you, all, for making my life so much brighter.",
  {
    format: "unordered-list",
    content: "I have been a member of the Yakima Herald-Republic\'s Unleashed program for teen journalists for the past four years. This fall, I plan to enroll at the University of Washington to study computer science."
  }
]