import { Article, Author } from "../../articleInterfaces";
import { content } from "./content";
import clipping from "../resources/sep-7-24.pdf";
import map from "./map.jpg";
import building from "./building.jpg";

const ME: Author = {
  name: "Magnus Fulton",
  school: "West Valley High School",
  grade: "graduate"
};

const article: Article = {
  articleType: "column",
  author: [ ME ],
  date: new Date(2024, 8, 7),
  title: "In 2024, college still isn't a year or two away for some high schoolers",
  clipping,
  yakimaHeraldURL: "https://www.yakimaherald.com/unleashed/columnists/in-2024-college-still-isnt-a-year-or-two-away-for-some-high-schoolers/article_e9ff7384-68fb-11ef-ae33-7bdd1a98de7d.html",
  images: [
    {
      src: map,
      caption: "Many students have appreciated the campus map outside of Glenn Anthon Hall, the first building many Running Start students encounter at Yakima Valley College. Glenn Anthon houses many core classes, spanning math, science, and social science.",
      credit: ME
    },
    {
      src: building,
      caption: "Many students start their Running Start journeys here, at Yakima Valley College's Glenn Anthon Hall. The building houses many core classes - spanning math, science, and social science - as well as many study areas, academic supports, and clubs.",
      credit: ME
    }
  ],
  content
};

export default article;
