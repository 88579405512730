import { Article, Author } from "../../articleInterfaces";
import { content } from "./content";
import skatepark from "./skatepark.jpg";
import clipping from "../resources/sep-7-24.pdf";

const ME: Author = {
  name: "Magnus Fulton",
  school: "West Valley High School",
  grade: "graduate"
}

const article: Article = {
  images: [
    {
      src: skatepark,
      credit: ME,
      caption: "Yakima Skate Park at Chesterley Park is still beloved by skaters and park goers nearly 25 years after its creation. This local skater was doing some tricks on the half pipe on Aug. 21, 2024."

    }
  ],
  articleType: "news",
  author: [ ME ],
  yakimaHeraldURL: "https://www.yakimaherald.com/unleashed/news/in-2024-yakimas-skaters-still-have-their-first-home-of-their-own/article_3e7aae36-68f9-11ef-9862-5b91dd7c46a1.html",
  date: new Date(2024, 8, 7),
  title: "In 2024, Yakima's skaters still have their first home of their own",
  clipping,
  content
}

export default article;